<template>
  <div v-if="chartHasData" class="d-flex flex-column align-items-end gap-2">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex flex-column gap-3">
        <SpecificViewSpendChartLabels />

        <h6
          v-if="topSpendDate && showTopDate"
          class="top-date-header ps-3 pt-1 pb-2"
        >
          Top Spending Date:
          <span class="ps-2">{{ topSpendDate?.date }}</span>
        </h6>
      </div>

      <DropDownInput
        id="user-spend-chart-toggle"
        :value="selectedChartType"
        class="me-2"
        :options="chartTypeOpts"
        label=""
        @selected-option="setChartType"
      />
    </div>

    <BaseLineChart
      v-if="selectedChartType === 'line'"
      class="w-100 h-100"
      :primary-data="chartData?.current"
      :secondary-data="chartData?.relative"
      :data="sortedData"
      :custom-default-tooltip-call-back="true"
      @toolTipCallBack="($event) => $emit('toolTipCallBack', $event)"
    />
    <BaseGroupedBarChart
      v-else
      class="w-100 h-100"
      :primary-data="chartData?.current"
      :secondary-data="chartData?.relative"
      :data="sortedData"
      :custom-default-tooltip-call-back="true"
      @toolTipCallBack="($event) => $emit('toolTipCallBack', $event)"
    />
  </div>
  <BaseEmptyState v-else min-height="45vh" />
</template>
<script>
import SpecificViewSpendChartLabels from "./SpecificViewSpendChartLabels.vue";
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import { computed, ref } from "vue";
import BaseLineChart from "./charts/BaseLineChart.vue";
import BaseGroupedBarChart from "./charts/BaseGroupedBarChart.vue";
import { useStore } from "vuex";
import { useDiffCalculator } from "@/composables/Helpers";
import DropDownInput from "./helpers/DropDownInput.vue";
import moment from "moment";

export default {
  components: {
    BaseLineChart,
    BaseGroupedBarChart,
    DropDownInput,
    BaseEmptyState,
    SpecificViewSpendChartLabels,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    chartSumData: {
      type: Object,
      default: () => {},
    },
    showTopDate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:chartSumData", "toolTipCallBack"],
  setup(props, { emit }) {
    const store = useStore();
    const chartTypeOpts = ref([
      { key: "line", value: "Line Chart" },
      { key: "bar", value: "Bar Chart" },
    ]);
    const selectedChartType = ref("line");
    const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    const metricFilters = computed(
      () => store.state.overViewStore.metricFilters
    );
    const timeDaysCount = computed(() => store.getters.timeDaysCount);

    const chartSumDataComputed = computed(() => {
      let data = {
        avgSum: 0,
        relAvgSum: 0,
        totalSum: 0,
        relTotalSum: 0,
        avgDiffPercentage: null,
        totalDiffPercentage: null,
      };

      // == get Total spend object keys based on different days count ==
      const timeDaysCount = timeFilters.value?.periodFilter ?? 30;
      const { current: curTotalKey, relative: relTotalKey } =
        getTotalKeys(timeDaysCount);

      const pointsCount = (props.data ?? []).length ?? 0;

      //if empty data, => exit
      if (pointsCount === 0) {
        emit("update:chartSumData", data);
        return data;
      }

      for (let dataPoint in props.data) {
        const dataObj = props.data[dataPoint];
        // console.log(dataObj);

        // === [1] average sum for current and relative values ===
        data.avgSum += Math.abs(dataObj.tenant_customers_avg_spend);
        data.relAvgSum += Math.abs(
          dataObj.comparison_period_tenant_customers_avg_spend
        );

        // === [2] total sum for current and relative values ===
        data.totalSum += Math.abs(dataObj[curTotalKey]);
        data.relTotalSum += Math.abs(dataObj[relTotalKey]);
      }

      // console.log("Points: ", pointsCount);
      // console.log("data.avgSum: ", data.avgSum);
      // console.log("data.relAvgSum: ", data.relAvgSum);
      // console.log("data.totalSum: ", data.totalSum);
      // console.log("data.relTotalSum: ", data.relTotalSum);

      // === round all values ===
      data = {
        avgSum: Math.round(data.avgSum / pointsCount),
        relAvgSum: Math.round(data.relAvgSum / pointsCount),
        totalSum: Math.round(data.totalSum),
        relTotalSum: Math.round(data.relTotalSum),
      };

      // == [3] difference percenrtage ==
      data.avgDiffPercentage = useDiffCalculator(data.avgSum, data.relAvgSum);
      data.totalDiffPercentage = useDiffCalculator(
        data.totalSum,
        data.relTotalSum
      );
      // == [4] emit v-model value for parent component ==
      emit("update:chartSumData", data);
      return data;
    });

    const chartHasData = computed(() => {
      const hasAvgValues = !!(
        chartSumDataComputed.value?.avgSum ||
        chartSumDataComputed.value?.relAvgSum
      );

      const hasTotalValues = !!(
        chartSumDataComputed.value?.totalSum ||
        chartSumDataComputed.value?.relTotalSum
      );

      return metricFilters.value?.key === "average"
        ? hasAvgValues
        : hasTotalValues;
    });

    const sortedData = computed(() => {
      let dataList = Object.values(props.data ?? {});
      return dataList.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(a?.tenant_transactions_date) -
          new Date(b?.tenant_transactions_date)
        );
      });
    });

    const chartData = computed(() => {
      let data = { current: [], relative: [] };

      const timeDaysCount = timeFilters.value?.periodFilter ?? 30;
      const { current: curTotalKey, relative: relTotalKey } =
        getTotalKeys(timeDaysCount);

      //Check if empty data, => exit
      const pointsCount = (props.data ?? [])?.length ?? 0;
      if (pointsCount === 0) return data;

      //Format the data
      for (let dataPoint in props.data) {
        const dataObj = props.data[dataPoint];

        // == [1] set current data points ==
        data.current.push({
          x: dataObj?.tenant_transactions_date,
          y:
            metricFilters.value.key === "average"
              ? Math.round(Math.abs(dataObj?.tenant_customers_avg_spend))
              : Math.round(Math.abs(dataObj[curTotalKey])),
        });

        // == [2] set relative data points ==
        data.relative.push({
          x: dataObj?.tenant_transactions_date,
          y:
            metricFilters.value.key === "average"
              ? Math.round(
                  Math.abs(
                    dataObj?.comparison_period_tenant_customers_avg_spend
                  )
                )
              : Math.round(Math.abs(dataObj[relTotalKey])),
        });
      }

      return {
        current: data.current.sort((a, b) => {
          return new Date(a.x) - new Date(b.x);
        }),
        relative: data.relative.sort((a, b) => {
          return new Date(a.x) - new Date(b.x);
        }),
      };
    });

    const topSpendDate = computed(() => {
      const values = Object.values(chartData.value?.current ?? {});

      if (values.length !== 0) {
        const max = values.reduce((prev, current) => {
          return (prev?.y ?? 0) > Math.abs(current?.y ?? 0) ? prev : current;
        });

        return {
          date: moment(max?.x).format(
            timeDaysCount.value.key === "monthly" ? "MMMM, YYYY" : "D MMM, YYYY"
          ),
          data: max,
        };
      }

      return false;
    });

    function setChartType(selectedValue) {
      selectedChartType.value = selectedValue;
    }

    function getTotalKeys() {
      let totalObjKey = {
        current: "tenant_customers_total_spend",
        relative: "comparison_period_tenant_customers_total_spend",
      };

      return totalObjKey;
    }

    return {
      chartData,
      chartSumDataComputed,
      chartHasData,
      chartTypeOpts,
      selectedChartType,
      setChartType,
      sortedData,
      topSpendDate,
    };
  },
};
</script>
<style scoped lang="scss">
.top-date-header {
  color: var(--dark-green-60, #66827f);
  font-size: 0.88rem !important;
  font-weight: 400;

  span {
    color: var(--dark-green-100, #002e2a);
  }
}
</style>
