<template>
  <div>
    <BaseBarChart
      v-if="chartData.length !== 0"
      class="w-100 h-100"
      :data="chartData"
      :tooltip="toolTip"
      height="36vh"
    />
    <BaseEmptyState v-else min-height="36vh" />
  </div>
</template>
<script>
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import BaseBarChart from "./charts/BaseBarChart.vue";
import { computed } from "vue";
import { ageBarChartTooltip } from "@/composables/ChartTooltips";
export default {
  components: { BaseBarChart, BaseEmptyState },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["handleViewAllUsers"],
  setup(props, { emit }) {

    const chartData = computed(() => {
      let dataList = props.data ?? [];

      if (dataList.length !== 0) {
        dataList = dataList.map((ageObject) => {
          let labelIndex = ageObject?.tenant_customers_age_label;
          labelIndex = labelIndex.split("-");

          if (!isNaN(Number(labelIndex[0]))) {
            labelIndex = Number(labelIndex[0]);
          } else if (labelIndex[0] === "below 21") {
            labelIndex = 20;
          } else if (labelIndex[0] === "Over 60") {
            labelIndex = 60;
          }

          return {
            x: ageObject?.tenant_customers_age_label ?? "--",
            y: ageObject?.tenant_customers_count ?? 0,
            labelIndex,
            ...ageObject,
          };
        });

        //sor the data in ascebding order based on index label
        dataList = dataList.sort((a, b) => {
          const current = a.labelIndex;
          const next = b.labelIndex;

          return current - next;
        });

        return dataList;
      }

      return [];
    });

    const toolTip = ageBarChartTooltip(chartData.value, (clickedData) => {
      emit("handleViewAllUsers", clickedData);
    });

    return { chartData, toolTip };
  },
};
</script>
<style></style>
