<template>
  <div>
    <BaseCardView
      title="Gender"
      :loading="gendersLoading || loading"
      loading-height="35vh"
    >
      <template #content>
        <SpecificViewGendersChart
          :data="gendersData"
          @handleViewAllUsers="($event) => $emit('handleViewAllUsers', $event)"
        />
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import SpecificViewGendersChart from "./SpecificViewGendersChart.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { ref, computed, watch } from "vue";
import BrandsAPI from "@/services/api/BrandsAPI";

export default {
  components: {
    BaseCardView,
    SpecificViewGendersChart,
  },
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleViewAllUsers"],
  setup(props) {
    const gendersData = ref([]);
    const gendersLoading = ref(false);

    const filters = computed(() => {
      return props.filters;
    });

    const getBrandsUsersGendersData = () => {
      gendersLoading.value = true;
      gendersData.value = [];

      BrandsAPI.getBrandsUsersGendersData(filters.value)
        .then((response) => {
          gendersData.value = response.data?.demographics ?? [];
          gendersLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            gendersData.value = [];
            gendersLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getBrandsUsersGendersData();
    };

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return {
      gendersData,
      gendersLoading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
