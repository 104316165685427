<template>
  <div class="h-100 pb-4 px-4 hide-scrollbar">
    <!--Date Range-->
    <div class="py-4">
      <SpecificViewInfoText :fetch-key="fetchKey" :filters="filters" />
    </div>
    <!--Date Range-->

    <!--Overview-->
    <SpecificViewOverviewBar
      :fetch-key="fetchKey"
      :filters="filters"
      :loading="specificBrandLoading"
      @handleViewAllUsers="viewUsersByBrand"
      @handleViewAllTxs="viewTxsByBrand"
    />
    <!--Overview-->

    <div
      class="
        pt-4
        mt-4
        mb-4
        w-100
        h-100
        d-flex
        flex-wrap
        justify-content-between
      "
    >
      <!--Spending-->
      <SpecificViewSpendingChartFull
        class="col-12 pb-4"
        :fetch-key="fetchKey"
        :filters="filters"
        :loading="specificBrandLoading"
        @toolTipCallBack="($event) => viewTxsByBrandAndDate($event)"
      />
      <!--Spending-->

      <!--Transactions-->
      <SpecificViewTxs
        :fetch-key="fetchKey"
        :filters="filters"
        :loading="specificBrandLoading"
        class="col-12 col-lg-6 p-4 px-0 pe-lg-4"
        @handleViewAllTxs="viewTxsByBrand"
      />
      <!--Transactions-->

      <div
        class="col-12 col-lg-6 p-4 pt-0 pe-0 ps-0 ps-lg-4 d-flex flex-column"
      >
        <div
          class="d-flex justify-content-between align-items-center flex-nowrap"
        >
          <!--Title-->
          <BaseCardTitle class="pt-4 pb-3 w-100" title="Customers" />
          <!--Title-->

          <!--Users Count Title-->
          <SpecificViewUsersCountTitle
            :style="`visibility: ${
              specificBrandLoading ? 'hidden' : 'visible'
            }`"
            @handleViewAllUsers="viewUsersByBrand"
          />
          <!--Users Count Title-->
        </div>

        <!--Country-->
        <SpecificViewCountries
          :fetch-key="fetchKey"
          :filters="filters"
          :loading="specificBrandLoading"
          class="py-4 pb-4"
          @handleViewAllUsers="viewUsersByBrandAndCountries"
        />
        <!--Country-->

        <!--Gender-->
        <SpecificViewGenders
          :fetch-key="fetchKey"
          :filters="filters"
          :loading="specificBrandLoading"
          class="py-4 pb-4"
          @handleViewAllUsers="viewUsersByBrandAndGenders"
        />
        <!--Gender-->

        <!--Age-->
        <SpecificViewAges
          :fetch-key="fetchKey"
          :filters="filters"
          :loading="specificBrandLoading"
          class="pt-4 mb-5"
          @handleViewAllUsers="viewUsersByBrandAndAges"
        />
        <!--Age-->
      </div>
    </div>
  </div>
</template>

<script>
import SpecificViewOverviewBar from "../components/SpecificViewOverviewBar.vue";
import SpecificViewSpendingChartFull from "../components/SpecificViewSpendingChartFull.vue";
import SpecificViewAges from "../components/SpecificViewAges.vue";
import SpecificViewGenders from "../components/SpecificViewGenders.vue";
import SpecificViewCountries from "../components/SpecificViewCountries.vue";
import SpecificViewUsersCountTitle from "../components/SpecificViewUsersCountTitle.vue";
import SpecificViewTxs from "../components/SpecificViewTxs.vue";
import SpecificViewInfoText from "../components/SpecificViewInfoText.vue";
import BaseCardTitle from "../components/helpers/BaseCardTitle.vue";
import { ref, computed, onMounted, onUnmounted, watch, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";
import { ageGroupsMapper } from "../composables/Helpers";

export default {
  components: {
    BaseCardTitle,
    SpecificViewInfoText,
    SpecificViewTxs,
    SpecificViewUsersCountTitle,
    SpecificViewCountries,
    SpecificViewGenders,
    SpecificViewAges,
    SpecificViewSpendingChartFull,
    SpecificViewOverviewBar,
  },
  props: {
    brandId: {
      type: String,
      default: "",
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const fetchKey = ref(0);

    const getCountry = inject("getCountry");
    const capitalizeFirstLetter = inject("capitalizeFirstLetter");

    // == Computed ==
    const specificBrandDetails = computed(
      () => store.state.allBrandsStore.specificBrandDetails
    );
    const specificBrandLoading = computed(
      () => store.state.allBrandsStore.specificBrandLoading
    );
    const filters = computed(() => {
      return {
        ...store.getters.filters,
        brand_ids: [props.brandId],
      };
    });

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);
    // == Computed ==

    // == Functions ==
    const fetchData = () => {
      //[1] Re-fetch event will be raised
      fetchKey.value += 1;

      //[2] Abort all previous pending requests
      store.dispatch("CANCEL_PENDING_REQUESTS");
    };
    function getDateRange(txDate) {
      let from;
      let to;
      let label;

      const daysCount = timeFilters.value?.periodFilter;
      switch (true) {
        case daysCount <= 31: //Daily
          from = txDate;
          to = txDate;
          label = moment(txDate).format("D MMM, YYYY");
          break;

        case daysCount > 31 && daysCount < 365: //Weekly
          // eslint-disable-next-line no-case-declarations
          const endingDate = new Date(txDate);
          // eslint-disable-next-line no-case-declarations
          const startingDate = new Date(
            endingDate.getFullYear(),
            endingDate.getMonth(),
            endingDate.getDate() - 6
          );

          from = startingDate;
          to = endingDate;
          label =
            moment(from).format("DD MMM, YYYY") +
            " - " +
            moment(to).format("DD MMM, YYYY");
          break;

        case daysCount >= 365: //Monthly
          // eslint-disable-next-line no-case-declarations
          let date = new Date(txDate);
          // eslint-disable-next-line no-case-declarations
          let year = date.getFullYear();
          // eslint-disable-next-line no-case-declarations
          let month = date.getMonth() + 1;

          from = new Date(year, month - 1, 1);
          to = new Date(year, month, 0);
          label =
            moment(from).format("DD MMM, YYYY") +
            " - " +
            moment(to).format("DD MMM, YYYY");
          break;
      }

      return {
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
        label,
      };
    }

    //Overview Events
    function viewTxsByBrand() {
      //[1] set the brand
      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);

      //[2] redirect
      router.push({
        name: "MainTransactionsByBrand",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }

    function viewUsersByBrand() {
      //[1] set the brand
      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);

      //[2] re-direct
      router.push({
        name: "UsersMainFromBrand",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }
    //Overview Events

    //Spend Chart Events
    function viewTxsByBrandAndDate(dataPoint) {
      //[1] Set the current brand filter
      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);

      //[2] compute the date filter
      const date = getDateRange(dataPoint?.tenant_transactions_date);

      //[3] Route to Transactions View
      router.push({
        name: "MainTransactionsByBrand",
        params: {
          //replace with date filter
          timeFilter: JSON.stringify({
            key: "custom",
            rangePeriodFilter: {
              from: date?.from,
              to: date?.to,
            },
            dropDownLabel: date?.label,
            periodFilter: 1,
          }),
          filters: JSON.stringify({
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }
    //Spend Chart Events

    //Users Countries Events
    function viewUsersByBrandAndCountries(clickedItem) {
      //[1] set the current brand in the filters
      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);

      //[2] re-direct
      router.push({
        name: "MainTransactionsByBrand",
        params: {
          filters: JSON.stringify({
            nationality: [
              {
                key: clickedItem?.y,
                value: getCountry(clickedItem?.y),
              },
            ],
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }
    //Users Countries Events

    //Users Gender Events
    function viewUsersByBrandAndGenders(clickedItem) {
      //[1] set the current brand in the filters
      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);

      //[2] re-direct
      router.push({
        name: "MainTransactionsByBrand",
        params: {
          filters: JSON.stringify({
            gender: [
              {
                key: clickedItem?.label,
                value: capitalizeFirstLetter(clickedItem?.label),
              },
            ],
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }
    //Users Genders Events

    //Users Gender Events
    function viewUsersByBrandAndAges(clickedItem) {
      //[1] set the current brand in the filters
      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);

      //[2] re-direct
      router.push({
        name: "MainTransactionsByBrand",
        params: {
          //replace with date filter
          filters: JSON.stringify({
            age: [ageGroupsMapper(clickedItem?.tenant_customers_age_label)],
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }
    //Users Genders Events

    function handleViewAllTxs() {
      console.log("handleViewAllTxs");

      store.commit("updateSelectedBrandsFilter", [specificBrandDetails.value]);
      router.push({
        name: "MainTransactionsByBrand",
        params: {
          filters: JSON.stringify({
            multi_currency: [
              {
                key: filters.value.tenant_transaction_currency_codes?.[0],
                value: filters.value.tenant_transaction_currency_codes?.[0],
              },
            ],
          }),
        },
      });
    }

    function handleViewAllUsers() {
      console.log("handleViewAllUsers");

      // store.commit("updateShowFiltersBar", true);
      // router.push({
      //   name: "UsersView",
      //   params: {
      //     filters: JSON.stringify({
      //       // multi_currency: [
      //       //   // { key: currentCurrency.value, value: currentCurrency.value },
      //       // ],
      //     }),
      //   },
      // });
    }

    // == Functions ==

    // == Hooks ==
    onMounted(() => {
      //fetch on mount
      fetchData();
    });

    onUnmounted(() => {
      store.dispatch("CANCEL_PENDING_REQUESTS");
    });
    // == Hooks ==

    // == Watchrs ==
    watch(filters, (currFilters, prevFilters) => {
      if (prevFilters?.tenant_transaction_from_date) {
        fetchData();
      }
    });
    // == Watchrs ==

    return {
      fetchKey,
      filters,
      handleViewAllTxs,
      handleViewAllUsers,
      specificBrandDetails,
      specificBrandLoading,
      viewTxsByBrand,
      viewUsersByBrand,
      viewTxsByBrandAndDate,
      viewUsersByBrandAndCountries,
      viewUsersByBrandAndGenders,
      viewUsersByBrandAndAges,
    };
  },
};
</script>

<style lang="scss" scoped></style>
