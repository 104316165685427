<template>
  <div>
    <BaseCardView title="Transactions">
      <template #header>
        <div class="ms-auto">
          <!--Txs Amount Title-->
          <SpecificViewTxsAmountTitle />
          <!--Txs Amountt Title-->
        </div>
      </template>

      <template #content>
        <SpecificCurrencyTxsList
          :data="transactionsData"
          :loading="transactionsLoading || loading"
          @updateTable="updateTable"
          @onSortChange="onSortChange"
          @viewAllClicked="$emit('handleViewAllTxs')"
        />
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import SpecificViewTxsAmountTitle from "./SpecificViewTxsAmountTitle.vue";
import SpecificCurrencyTxsList from "./SpecificCurrencyTxsList.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import OverviewAPI from "@/services/api/OverviewAPI";
import { ref, computed, watch } from "vue";

export default {
  components: {
    BaseCardView,
    SpecificCurrencyTxsList,
    SpecificViewTxsAmountTitle,
  },
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleViewAllTxs"],
  setup(props) {
    const transactionsData = ref({});
    const transactionsLoading = ref(false);

    const sortObject = ref({
      field: "amount",
      order: -1,
    });

    const filters = computed(() => {
      return props.filters;
    });

    const parseTxData = (res) => {
      const reponse = res.data;

      return {
        transactions: reponse?.tenant_customers_transactions ?? [],
        total_records: reponse?.tenant_customers_transactions_count ?? 0,
      };
    };

    const getTransfersTransactions = (reload = true) => {
      transactionsLoading.value = reload;
      if (reload) {
        transactionsData.value = {};
      }

      OverviewAPI.getAllUsersTransactions({
        ...filters.value,
        page: 1,
        per_page: 20,
        sort_by: sortObject.value?.field,
        order: sortObject.value?.order == -1 ? "desc" : "asc",
      })
        .then((res) => {
          transactionsData.value = parseTxData(res);
          transactionsLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            transactionsData.value = {};
            transactionsLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getTransfersTransactions();
    };

    const updateTable = () => {
      getTransfersTransactions(false);
    };

    const onSortChange = (sortObj) => {
      //[1] Set new sort option
      sortObject.value = { ...sortObj };

      //[2] Fetch new tx's
      getTransfersTransactions();
    };

    // function viewAllClicked() {
    //   store.commit("updateShowFiltersBar", true);
    //   router.push({
    //     name: "MainTransactions",
    //     params: {
    //       filters: JSON.stringify({
    //         multi_currency: [
    //           // { key: props.selectedCurrency, value: props.selectedCurrency },
    //         ],
    //       }),
    //     },
    //   });
    // }

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return {
      transactionsData,
      transactionsLoading,
      updateTable,
      onSortChange,
    };
  },
};
</script>

<style lang="scss" scoped></style>
