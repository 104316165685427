<template>
  <div class="w-100 h-100 d-flex flex-column">
    <div v-if="sortedList.length !== 0" class="d-flex flex-column">
      <div class="d-flex gap-3 justify-content-start align-items-start">
        <BaseSortButton
          label="A-Z"
          :sort-is-active="sortOption == sortOptions.label"
          :reverse-sorting="sortOption == 'reverse-0'"
          @click="sortData(sortOptions.label)"
        />

        <BaseSortButton
          label="No. of users"
          :sort-is-active="sortOption == sortOptions.users"
          :reverse-sorting="sortOption == 'reverse-1'"
          @click="sortData(sortOptions.users)"
        />
      </div>
      <BaseHorizontalBarChart
        :disable-selection="true"
        :data="[...sortedList]"
        :tooltip="toolTip"
        :value-decimals="1"
        height="37vh"
        :is-full-chart="isFullChart"
      />
    </div>
    <BaseEmptyState v-else min-height="37vh" />

    <div
      v-if="!isFullChart"
      class="w-100 d-flex justify-content-end align-items-start"
    >
      <BaseSmallButton
        label="All Countries"
        :end-icon="require('@/assets/images/svg/components/right-arrow.svg')"
        @click="$emit('onViewAll')"
      />
    </div>
  </div>
</template>

<script>
import BaseSmallButton from "./helpers/BaseSmallButton.vue";
import BaseEmptyState from "./helpers/BaseEmptyState.vue";
import BaseSortButton from "./helpers/BaseSortButton.vue";
import { computed, reactive, ref } from "vue";
import BaseHorizontalBarChart from "./charts/BaseHorizontalBarChart.vue";
import { currencyCountryTooltip } from "../composables/ChartTooltips";
import { useStore } from "vuex";

export default {
  components: {
    BaseHorizontalBarChart,
    BaseSortButton,
    BaseEmptyState,
    BaseSmallButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isFullChart: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onViewAll", "onModelClose", "handleViewAllUsers"],
  setup(props, { emit }) {
    //== States ==
    const store = useStore();

    const sortOption = ref(1);
    const sortOptions = reactive({
      label: 0,
      users: 1,
    });
    //== States ==

    //== Computed ==
    const chartData = computed(() => {
      const dataList = props.data;

      if (dataList.length === 0) return [];

      return dataList.map((dataPoint) => {
        return {
          x: dataPoint?.tenant_customers_count ?? 0,
          y: dataPoint?.tenant_customers_nationality ?? "--",
        };
      });
    });

    const sortedList = computed(() => {
      const propList = chartData.value;
      // exit if empty
      if (propList.length === 0) return [];

      return propList.sort((a, b) => {
        let res = 0;

        switch (sortOption.value) {
          case sortOptions.label:
            res = b.y.localeCompare(a.y);
            break;

          case `reverse-${sortOptions.label}`:
            res = a.y.localeCompare(b.y);
            break;

          case sortOptions.users:
            res = (b.x ?? 0) - (a.x ?? 0);
            break;

          case `reverse-${sortOptions.users}`:
            res = (a.x ?? 0) - (b.x ?? 0);
            break;

          default:
            break;
        }

        return res;
      });
    });
    //== Computed ==

    //== Functions ==
    function sortData(option) {
      sortOption.value =
        sortOption.value === option ? `reverse-${option}` : option;
    }
    //== Functions ==

    const toolTip = currencyCountryTooltip(chartData.value, (clickedItem) => {
      if (clickedItem?.y && clickedItem?.y !== "--") {
        //[1] Open Top Bar
        store.commit("updateShowFiltersBar", true);

        //[2] Emit close modal
        emit("onModelClose");

        //[3] Re-direct
        emit("handleViewAllUsers", clickedItem);
      }
    });

    return {
      chartData,
      sortedList,
      sortOption,
      sortOptions,
      sortData,
      toolTip,
    };
  },
};
</script>

<style lang="scss" scoped></style>
