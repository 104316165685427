<template>
  <BaseSmallAmountTitle
    :is-clickable="true"
    :show-currency="false"
    :show-trend-sign="true"
    :amount="
      compactNumber(selectedCurrencyOverview?.tenant_customers_count ?? 0)
    "
    custom-icon-size="18px"
    font-size="1.22rem"
    :is-trending-positively="
      selectedCurrencyOverview?.customersCountDiff?.diffSign
    "
    :custom-icon="require('@/assets/images/svg/components/users-sm.svg')"
    @click="$emit('handleViewAllUsers')"
  />
</template>

<script>
import { useStore } from "vuex";
import BaseSmallAmountTitle from "./helpers/BaseSmallAmountTitle.vue";
import { computed, inject } from "vue";
// import { useRouter } from "vue-router";

export default {
  components: { BaseSmallAmountTitle },
  emits: ["handleViewAllUsers"],
  setup() {
    const compactNumber = inject("compactNumber");
    const store = useStore();
    // const router = useRouter();

    const selectedCurrencyOverview = computed(
      () => store.state.allCurrenciesStore.selectedCurrencyOverview
    );

    // function handleViewAllUsers() {
    //   store.commit("updateShowFiltersBar", true);
    //   router.push({
    //     name: "UsersView",
    //     params: {
    //       filters: JSON.stringify({
    //         multi_currency: [
    //           // { key: props.selectedCurrency, value: props.selectedCurrency },
    //         ],
    //       }),
    //     },
    //   });
    // }

    return {
      compactNumber,
      selectedCurrencyOverview,
      // handleViewAllUsers,
    };
  },
};
</script>

<style lang="scss" scoped></style>
