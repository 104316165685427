<template>
  <div>
    <BaseCardView
      :loading="spendChartLoading || loading"
      title="Spending"
      loading-height="35vh"
    >
      <template #content>
        <SpecificViewSpendChart
          :data="spendChartData"
          :show-top-date="true"
          @toolTipCallBack="($event) => $emit('toolTipCallBack', $event)"
        />
      </template>
    </BaseCardView>
  </div>
</template>

<script>
import SpecificViewSpendChart from "./SpecificViewSpendChart.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import BrandsAPI from "@/services/api/BrandsAPI";

export default {
  components: { BaseCardView, SpecificViewSpendChart },
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toolTipCallBack"],
  setup(props) {
    const store = useStore();
    const spendChartData = ref({});
    const spendChartLoading = ref(false);

    const filters = computed(() => {
      const reqFilters = { ...props.filters };

      //remove the brand_ids[]
      delete reqFilters?.brand_ids;

      return reqFilters;
    });

    const selectedBrandId = computed(() => {
      const reqFilters = { ...props.filters };
      return reqFilters?.brand_ids?.[0];
    });

    const timeFilters = computed(() => store.state.overViewStore.timeFilters);

    const getBrandsSpendChartData = () => {
      spendChartLoading.value = true;
      spendChartData.value = [];

      BrandsAPI.getBrandsSpendChartData(
        filters.value,
        timeFilters.value?.periodFilter ?? 30,
        selectedBrandId.value
      )
        .then((res) => {
          spendChartData.value = [...(res.data?.tenant_customers_spends ?? [])];
          spendChartLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            spendChartData.value = [];
            spendChartLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getBrandsSpendChartData();
    };

    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return { spendChartData, spendChartLoading };
  },
};
</script>

<style lang="scss" scoped></style>
