<template>
  <div>
    <BaseCardView
      title="Country"
      :loading="countriesLoading || loading"
      loading-height="37vh"
    >
      <template #content>
        <SpecificViewCountriesChart
          :data="countriesData"
          @onViewAll="onViewAll"
          @handleViewAllUsers="($event) => $emit('handleViewAllUsers', $event)"
        />
      </template>
    </BaseCardView>

    <!--Full Screen Chart-->
    <SpecificViewCountriesFullChart
      id="currency-cuntries-chart"
      :countries-data="countriesData"
      :show-modal="showReportForm"
      @closeModal="closeModal"
      @handleViewAllUsers="($event) => $emit('handleViewAllUsers', $event)"
    />
    <!--Full Screen Chart-->
  </div>
</template>

<script>
import SpecificViewCountriesFullChart from "./SpecificViewCountriesFullChart.vue";
import SpecificViewCountriesChart from "./SpecificViewCountriesChart.vue";
import BaseCardView from "./helpers/BaseCardView.vue";
import { ref, computed, watch } from "vue";
import BrandsAPI from "@/services/api/BrandsAPI";

export default {
  components: {
    BaseCardView,
    SpecificViewCountriesChart,
    SpecificViewCountriesFullChart,
  },
  props: {
    fetchKey: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleViewAllUsers"],
  setup(props) {
    const countriesData = ref([]);
    const countriesLoading = ref(false);
    const showReportForm = ref(false);

    const filters = computed(() => {
      return props.filters;
    });

    const getBrandsUsersCountriesData = () => {
      countriesLoading.value = true;
      countriesData.value = [];

      BrandsAPI.getBrandsUsersCountriesData(filters.value)
        .then((response) => {
          countriesData.value = response.data?.demographics ?? [];
          countriesLoading.value = false;
        })
        .catch((error) => {
          if (error !== "canceled") {
            countriesData.value = [];
            countriesLoading.value = false;
            console.error("error: ", error);
          }
        });
    };

    const fetch = () => {
      getBrandsUsersCountriesData();
    };

    const closeModal = () => {
      showReportForm.value = false;
    };
    const onViewAll = () => {
      if (showReportForm.value) {
        showReportForm.value = false;
        return;
      }
      showReportForm.value = true;
    };
    watch(
      () => props.fetchKey,
      () => {
        fetch();
      }
    );

    return {
      countriesData,
      countriesLoading,
      showReportForm,
      closeModal,
      onViewAll,
    };
  },
};
</script>

<style lang="scss" scoped></style>
